import React from "react";
import Navbar from "./Components/Navbar";
import ExcalidrawImg from "./assets/excalidraw.png"; // Replace with your Excalidraw image
import OnPaperImg from "./assets/on_paper.jpg"; // Replace with your OnPaper image
import ToDoListsImg from "./assets/todo_lists.png"; // Replace with your ToDoLists image
import { useMediaQuery } from 'react-responsive';

function Workflow() {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "#2d2a2e",
    minHeight: "100vh",
  };

  const sectionStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    textAlign: "left",
    padding: "20px",
    margin: "20px",
    borderRadius: "10px",
    width: isMobile ? "90%" : "80%",
    maxWidth: "900px",
    backgroundColor: "#353336",
    color: "#f8f8f2",
  };

  const textContentStyle = {
    maxWidth: "70%",
    color: "#f8f8f2",
    fontFamily: "'Roboto', sans-serif",
    fontSize: "14px",
    lineHeight: "1.6",
  };

  const headingStyle = {
    color: "#f8f8f2",
    fontFamily: "'Roboto', sans-serif",
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "10px",
  };

  const subHeadingStyle = {
    color: "#ffd866",
    fontFamily: "'Roboto', sans-serif",
    fontSize: "16px",
    marginBottom: "10px",
    marginTop: "20px",
  };

  const imgStyle = {
    width: "100%",
    maxWidth: "500px",
    borderRadius: "10px",
    marginTop: "10px",
    marginBottom: "10px",
  };

  const about = {
    width: isMobile ? "80%" : "75%",
    color: "#f8f8f2",
    fontFamily: "'Roboto', sans-serif",
    fontSize: "14px",
  }

  return (
    <div className="App">
      <Navbar />
      <div style={containerStyle}>
        <div style={sectionStyle}>

          <p style={subHeadingStyle}>Problem Solving</p>
          <p style={about}>When working on projects that are big in scale or particularly challenging, I follow a structured methodology that helps me break down complex tasks into manageable steps. This approach not only allows me to visualize and plan effectively but also gives me the confidence to tackle problems that might initially seem too difficult or even impossible to solve.</p>
         

          {/* First Workflow Section */}
          <p style={subHeadingStyle}>
            Doing rough sketches to help me visualize and understand the
            connections.
          </p>
          <img
            src={ExcalidrawImg}
            alt="Excalidraw Sketch"
            style={imgStyle}
          />

         

          {/* Third Workflow Section */}
          <p style={subHeadingStyle}>
            Making to-do lists for everyday work, and main goals.
          </p>
          <img src={ToDoListsImg} alt="To-Do Lists" style={imgStyle} />

           {/* Second Workflow Section */}
           <p style={subHeadingStyle}>
            Writing things down on paper to quickly brainstorm ideas.
          </p>
          <img src={OnPaperImg} alt="On Paper" style={imgStyle} />
        </div>
      </div>
    </div>
  );
}

export default Workflow;
