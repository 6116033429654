import React, { useState } from "react";
import Navbar from "./Components/Navbar";
import ChevronUp from "./assets/chevron_up.png";
import CloseIcon from "./assets/close_icon.png";
import LeftArrow from "./assets/left_arrow.png";
import RightArrow from "./assets/right_arrow.png";
import { useMediaQuery } from 'react-responsive';

import AnimalFind from "./assets/animalfind_logo.png";
import yof from "./assets/yof_logo.png";
import mft from "./assets/mft_logo.png";
import avet from "./assets/avet_logo.png";
import santa from "./assets/santa_logo.png";

import animal0 from "./assets/animal0.png";
import animal1 from "./assets/animal1.png";
import animal2 from "./assets/animal2.png";
import animal3 from "./assets/animal3.png";
import animal4 from "./assets/animal4.png";

import VDB1 from "./assets/vdb1.png";
import VDB2 from "./assets/vdb2.png";
import VDB3 from "./assets/vdb3.png";
import VDB4 from "./assets/vdb4.png";
import VDB5 from "./assets/vdb5.png";
import santa1 from "./assets/santa1.png";
import santa2 from "./assets/santa2.png";
import santa3 from "./assets/santa3.png";
import santa4 from "./assets/santa4.png";
import yof1 from "./assets/yof1.png";
import mftIMG from "./assets/mft.png";

const ProjectsPage = () => {

  const isMobile = useMediaQuery({ maxWidth: 600 });

  const projectContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "#2d2a2e",
    minHeight: "100vh",
  };

  const projectAboutStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    textAlign: "left",
    padding: "20px",
    margin: "20px",
    borderRadius: "10px",
    width: isMobile ? "90%" : "80%",  // Increased width on mobile
    maxWidth: "900px",
    backgroundColor: "#353336",
    color: "#f8f8f2",
  };
  
  const textContentStyle = {
    maxWidth: isMobile ? "80%" : "70%",  // Increased maxWidth on mobile
    color: "#f8f8f2",
    fontFamily: "'Roboto', sans-serif",
    fontSize: "14px",
    lineHeight: "1.6",
  };
  

  const headingStyle = {
    color: "#f8f8f2",
    fontFamily: "'Roboto', sans-serif",
    marginBottom: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  };

  const timeSpentStyle = {
    color: "#f8f8f2",
    fontFamily: "'Roboto', sans-serif",
    marginBottom: "10px",
  };

  const techStackStyle = {
    color: "#78dce8",
    fontFamily: "'Roboto', sans-serif",
    marginBottom: "10px",
  };

  const shortSummaryStyle = {
    color: "#ffd866",
    fontFamily: "'Roboto', sans-serif",
    marginBottom: "10px",
  };

  const collapsibleTitleStyle = {
    fontFamily: "'Roboto', sans-serif",
    cursor: "pointer",
    marginBottom: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: "70%",
  };

  const highlightsStyle = { color: "#a6e22e" };
  const challengesStyle = { color: "#f92672" };

  const chevronStyle = (isOpen, color) => ({
    width: "20px",
    height: "20px",
    transform: isOpen ? "rotate(0deg)" : "rotate(180deg)",
    transition: "transform 0.3s ease",
    filter: `invert(${color === "#a6e22e" ? 60 : 40}%)`,
  });

  const listStyle = {
    listStyleType: "none",
    paddingLeft: "0",
  };

  const listItemStyle = {
    fontFamily: "'Roboto', sans-serif",
    color: "#f8f8f2",
    fontSize: "14px",
    marginBottom: "5px",
    maxWidth: "70%",
  };

  const imageRowStyle = {
    display: "flex",
    gap: "10px",
    marginTop: "10px",
    marginBottom: "10px",
  };

  const thumbnailStyle = {
    width: "50px",
    height: "50px",
    borderRadius: "5px",
    cursor: "pointer",
    objectFit: "cover",
  };

  const modalStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(45, 42, 46, 0.9)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  };
  
  const modalContentStyle = {
    position: "relative",
    display: "flex",
    flexDirection: isMobile ? "column" : "row", // Column for mobile, row for desktop
    alignItems: "center",
  };
  
  const modalImageStyle = {
    maxWidth: "80vw",
    maxHeight: "80vh",
    borderRadius: "10px",
    display: "block",
    margin: "0 auto",
  };
  
  const closeButtonStyle = {
    position: "absolute",
    top: "-25px",
    right: "-25px",
    cursor: "pointer",
    zIndex: 1001,
    width: "45px",
    height: "45px",
  };
  
  const arrowButtonStyle = {
    cursor: "pointer",
    zIndex: 1001,
    width: "40px",
    height: "40px",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: "50%",
    padding: "10px",
  };
  
  const leftArrowStyle = isMobile
    ? {
        ...arrowButtonStyle,
        marginRight: "10px", // Spacing for mobile layout
      }
    : {
        ...arrowButtonStyle,
        position: "absolute",
        top: "50%",
        left: "-60px", // Left of the image for desktop
        transform: "translateY(-50%)",
      };
  
  const rightArrowStyle = isMobile
    ? {
        ...arrowButtonStyle,
        marginLeft: "10px", // Spacing for mobile layout
      }
    : {
        ...arrowButtonStyle,
        position: "absolute",
        top: "50%",
        right: "-60px", // Right of the image for desktop
        transform: "translateY(-50%)",
      };
  
  const arrowsContainerStyle = isMobile
    ? {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "20px", // Add spacing below the image
      }
    : {
        display: "none", // No container needed for desktop
      };
  

  // Projects array with only the AnimalFind project
  const projects = [
    {
      name: "AnimalFind",
      timeSpent: "September 2024 – December 2024",
      techStack:
        "React Native, VPS Ubuntu, Node.js, Express.js, PostgreSQL, Sequelize, Docker, Bunny, Firebase Auth & Serveless Cloud Functions, Nginx, Google APIs",
        summary: "AnimalFind is a mobile app designed to help locate lost animals. It enables users to post about pets they've lost or found, incorporating a feed based on location and categories. This assists users in searching for animals reported by others in their vicinity. The backend infrastructure has been built and is managed by me on a VPS purchased specifically for these kinds of projects.",
        highlights: [
          "Created a custom backend on Mikr.us VPS, securing it thoroughly by connecting NGINX with Docker, adding rate limiting, secure headers, and securing all ports with iptables rules.",
          "Developed a feed based on location and added CRUD functionality to users' posts with simple and intuitive screens.",
          "Implemented an option to report user posts and backend functionality to review reported posts when they reach a certain threshold, making them invisible in the feed until reviewed.",
          "Added security measures such as Google Vision API for post uploading to ensure inappropriate content isn't added to the app, as well as reCAPTCHAs to protect features like viewing users' contact phones from being easily scraped by bots."
        ],
        challenges: [
          "Securing API keys by using Firebase Cloud Functions to handle sensitive keys on the backend, preventing exposure on the frontend and making requests securely.",
          "Ensuring the frontend remains responsive despite added latency from features like Google Places API by using promises and loads that occur as users fill out forms, making the experience appear instantaneous.",
          "Choosing the right approach to authentication; initial issues with Firebase led to complex debugging and ultimately finding a solution on a less-viewed StackOverflow post. This experience informed the decision to use Auth0 or Clerk for future projects."
        ],images:[
          animal0, animal1, animal2, animal3, animal4

        ],
      logo: AnimalFind,
      link : "https://animalfind.shepherdpaul.com",
      github: "https://github.com/p-shepherd/animalfind-opensource"
    },
    {
      name: "Vulndbase",
      timeSpent: "May 2024 – October 2024",
      techStack:
        "Python Flask, React, Python, PostgreSQL, Docker, Redis, Nginx, Gunicorn, Ubuntu",
      summary:
        "Vulndbase is an API with React Web for data showcase, and Flask Admin Panel, used as internal company tool and SaaS product designed to enhance security by identifying and searching for vulnerabilities in applications.",
      highlights: [
        "Designed and implemented a robust API enabling advanced vulnerability search capabilities despite low quality data.",
        "Developed a web app showcasing dynamic vulnerability statistics and visualizations.",
        "Optimized backend performance using Redis, significantly reducing server resource usage.",
        "Deployed and managed a fully containerized application hosted on an Ubuntu VPS in the Azure Cloud.",
        "Created a foundational SaaS product empowering clients with advanced vulnerability tracking tools.",
        "Wrote technical and user documentation for the API and web app, ensuring ease of use and maintenance.",
      ],
      challenges: [
        "The vast dataset of CVEs, often inconsistently described, made querying for accurate results particularly challenging. I tackled this by carefully designing an algorithm that processed data systematically, step by step, starting with handwritten flow diagrams. This methodical approach ensured that the API could reliably return accurate and relevant results, even when faced with messy data.",
        
        "Handling hundreds of thousands of data entries for frontend operations was resource-intensive, leading to high CPU and memory usage. By implementing Redis as a caching layer, I reduced backend query times and frontend resource consumption by 90%, ensuring smooth and responsive performance even under heavy loads.",
        
      ],images: [VDB1, VDB2 , VDB3, VDB4],
      logo: avet,
      link: "",
    },
    {
      name: "Postman Clone (Working Offline)",
      timeSpent: "March 2024 – May 2024",
      techStack: "React, Electron, Node.js, Redux",
      summary:
        "The Postman Clone is a desktop application designed for security professionals, offering a robust alternative to Postman that works entirely offline. This executable app supports a full range of HTTP methods with JSON payloads, making it ideal for secure environments without internet access. Key features include a proxy switch for integration with tools like Burp Suite, detailed request history, and intuitive input fields for parameters, headers, and JSON payloads.",
          highlights: [
        "Built a cross-platform desktop app supporting detailed request histories and proxy support.",
        "Integrated graphical response type distinction for improved user experience.",
      ],
      challenges: [
        "Bypassing CORS restrictions for testing APIs without modifying server configurations was a significant challenge. This was addressed by embedding a backend server on Node.js within the app, allowing seamless API interaction without browser limitations.",
        "Overcame challenges with cross-platform compatibility for Windows, macOS, and Linux with the help of Electron library",
      ],images:[],logo:avet,link: "",
    }, 
    {
      name: "Secret Santa (Your Own Draw)",
      timeSpent: "December 2023 / 1 week",
      techStack:
        "React, Firebase (Firestore, Authentication, Cloud Functions, Hosting)",
      summary:
        "A web app to facilitate Secret Santa draws for groups like families or friends who can't meet in person. Users can create groups, invite participants, and perform unique draws.",
      highlights: [
        "Successfully implemented the drawing mechanism in Firebase Firestore, ensuring unique results for each participant.",
        "Developed a real-time updated table of users with a TXT download option.",
        "Expanded my understanding of Firebase Cloud Functions by designing and deploying custom queue management logic.",
      ],
      challenges: [
        "Implemented a queue system using Firebase Cloud Functions to ensure unique results for simultaneous users.",
        "Overcame challenges in managing concurrent operations in Firestore to handle real-time updates efficiently.",
      ],images:[santa1, santa2, santa3, santa4],
      logo: santa,link: "https://your-group-draw.web.app",
    },
    {
      name: "YOF - Your Own Flashcards",
      timeSpent: "July 2023 – December 2023 (6 months)",
      techStack:
        "React Native, SVG, Gesture Flip Card Libraries, Firebase (Firestore, Authentication)",
      summary:
        "A mobile app designed for personalized learning, allowing users to create text-based and handwritten flashcards, organize them into categories, and engage in interactive study sessions.",
      highlights: [
        "Launched on the Google Play Store, built from the ground up.",
        "Reduced Firebase read operations by 95% through efficient database restructuring.",
        "Enhanced scalability by increasing the maximum flashcards from 10,000 total to 10,000 per subcategory.",
      ],
      challenges: [
        "Resolved inefficiencies in variable passing between screens by using React Navigation.",
        "Restructured Firestore database to meet scalability and size requirements.",
      ],images:[yof1],
      logo: yof,link: "https://play.google.com/store/apps/details?id=com.shepherdpaul.FlashcardProject&hl=en-US&ah=B3huCA4DNZen5lsHRAy75ZZ5dUM",
    },
    {
      name: "My Family Tree",
      timeSpent: "July 2023 – September 2023 (2 months)",
      techStack:
        "React, ReactFlow, Firebase (Firestore, Image Storage, Authentication)",
      summary:
        "My Family Tree was initially created for a family member who needed a free and customizable genealogical tree application. Working directly with them, and consulting the results and features, gave me a clear goal to work towards. Existing apps, even paid ones, lacked the functionality they required, such as rearranging components, diverse line connections, and the ability to add detailed context to nodes and their connections.",
      highlights: [
        "Built a fully customizable genealogical tree surpassing existing alternatives.",
        "Integrated Firebase services for real-time data storage and updates.",
          ],link: "https://myfamilytree-c0588.web.app/",
      challenges: [
        
        "While ReactFlow provided a robust base, significant customization was required to meet the project's needs. I extended its functionality to support multi-sided connections, interactive nodes featuring text and images, and dynamic node styling. Since ReactFlow provided the most user-friendly documentation I’ve ever seen, that went fairly smoothly.",
    
      ],images:[mftIMG],
      logo: mft,
    },
  ];

  // Initialize projectsState based on the projects array
  const [projectsState, setProjectsState] = useState(
    projects.map(() => ({
      showHighlights: false,
      showChallenges: false,
    }))
  );

  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [currentProjectImages, setCurrentProjectImages] = useState([]);

  const toggleHighlights = (index) => {
    setProjectsState((prevState) =>
      prevState.map((project, i) =>
        i === index
          ? { ...project, showHighlights: !project.showHighlights }
          : project
      )
    );
  };

  const toggleChallenges = (index) => {
    setProjectsState((prevState) =>
      prevState.map((project, i) =>
        i === index
          ? { ...project, showChallenges: !project.showChallenges }
          : project
      )
    );
  };

  const openImageModal = (images, index) => {
    setCurrentProjectImages(images);
    setSelectedImageIndex(index);
  };

  const closeImageModal = () => {
    setSelectedImageIndex(null);
    setCurrentProjectImages([]);
  };

  const showPreviousImage = (e) => {
    e.stopPropagation();
    setSelectedImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : currentProjectImages.length - 1
    );
  };

  const showNextImage = (e) => {
    e.stopPropagation();
    setSelectedImageIndex((prevIndex) =>
      prevIndex < currentProjectImages.length - 1 ? prevIndex + 1 : 0
    );
  };
  const linkStyle = {
    color: "#f8f8f2",
    textDecoration: "underline",
    cursor: "pointer",
    fontFamily: "'Roboto', sans-serif",
    fontSize: "14px",
    marginTop: "10px",
  };
  
  const linkHoverStyle = {
    color: "#ffd866", // Color matching the short summary style
  };

  return (
    <div className="App">
      <Navbar />

      <div style={projectContainerStyle}>
        {projects.map((project, index) => (
          <div style={projectAboutStyle} key={index}>
            <div style={headingStyle}>
              <h2 style={{ margin: 0 }}>{project.name}</h2>
              {/* Placeholder for project icon */}
              <img
                src={project.logo}
                alt="Project Icon"
                style={{ width: "80px", height: "80px", backgroundColor: "white", borderRadius: "50%" }}
              />
            </div>

            <div style={textContentStyle}>
              <p style={timeSpentStyle}>Time Spent: {project.timeSpent}</p>
              <p style={techStackStyle}>
                Tech Stack:
                <span style={textContentStyle}> {project.techStack}</span>
              </p>
              <p style={shortSummaryStyle}>Project Summary:</p>
              <p style={{ marginBottom: "20px" }}>{project.summary}</p>
            </div>

            {/* Highlights */}
            <div
              style={collapsibleTitleStyle}
              onClick={() => toggleHighlights(index)}
            >
              <p style={{ ...highlightsStyle }}>Highlights</p>
              <img
                src={ChevronUp}
                alt="Chevron Icon"
                style={chevronStyle(
                  projectsState[index].showHighlights,
                  "#a6e22e"
                )}
              />
            </div>
            {projectsState[index].showHighlights && (
              <ul style={listStyle}>
                {project.highlights.map((highlight, i) => (
                  <li key={i} style={listItemStyle}>
                    - {highlight}
                  </li>
                ))}
              </ul>
            )}

            {/* Challenges & Solutions */}
            <div
              style={collapsibleTitleStyle}
              onClick={() => toggleChallenges(index)}
            >
              <p style={{ ...challengesStyle }}>Challenges & Solutions</p>
              <img
                src={ChevronUp}
                alt="Chevron Icon"
                style={chevronStyle(
                  projectsState[index].showChallenges,
                  "#f92672"
                )}
              />
            </div>
            {projectsState[index].showChallenges && (
              <ul style={listStyle}>
                {project.challenges.map((challenge, i) => (
                  <li key={i} style={listItemStyle}>
                    - {challenge}
                  </li>
                ))}
              </ul>
            )}

            {/* Image Carousel */}
            {project.images && project.images.length > 0 && (
              <div style={imageRowStyle}>
                {project.images.slice(0, 5).map((image, i) => (
                  <img
                    key={i}
                    src={image}
                    alt={`Thumbnail ${i + 1}`}
                    style={thumbnailStyle}
                    onClick={() => openImageModal(project.images, i)}
                  />
                ))}
              </div>
            )}
            {project.link && (
    <a
      href={project.link}
      style={linkStyle}
      onMouseOver={(e) => (e.target.style.color = linkHoverStyle.color)}
      onMouseOut={(e) => (e.target.style.color = linkStyle.color)}
    >
      Link to the {project.name} App.
    </a>
  )}

  {project.github && (
        
    <a
      href={project.github}
      style={linkStyle}
      onMouseOver={(e) => (e.target.style.color = linkHoverStyle.color)}
      onMouseOut={(e) => (e.target.style.color = linkStyle.color)}
    >
     Link to the {project.name} GitHub Repository. 
    </a>
  )}
          </div>
          
        ))}
      </div>

   {/* Modal */}
{selectedImageIndex !== null && (
  <div style={modalStyle} onClick={closeImageModal}>
    <div style={modalContentStyle} onClick={(e) => e.stopPropagation()}>
      {/* Close Button */}
      <img
        src={CloseIcon}
        alt="Close Icon"
        style={closeButtonStyle}
        onClick={closeImageModal}
      />
      {/* Image */}
      <img
        src={currentProjectImages[selectedImageIndex]}
        alt="Selected"
        style={modalImageStyle}
      />
      {/* Arrows */}
      {isMobile ? (
        <div style={arrowsContainerStyle}>
          <img
            src={LeftArrow}
            alt="Previous"
            style={leftArrowStyle}
            onClick={showPreviousImage}
          />
          <img
            src={RightArrow}
            alt="Next"
            style={rightArrowStyle}
            onClick={showNextImage}
          />
        </div>
      ) : (
        <>
          <img
            src={LeftArrow}
            alt="Previous"
            style={leftArrowStyle}
            onClick={showPreviousImage}
          />
          <img
            src={RightArrow}
            alt="Next"
            style={rightArrowStyle}
            onClick={showNextImage}
          />
        </>
      )}
    </div>
  </div>
)}

    </div>
  );
};

export default ProjectsPage;
