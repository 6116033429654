import React, { useState, useEffect } from "react";
import Navbar from "./Components/Navbar";
import CatImage from "./ja.jpeg"; // Correctly importing the image

// Importing project icons
import AnimalFind from "./assets/animalfind_logo.png";
import avet from "./assets/avet_logo.png";

const App = () => {
  const [isWideScreen, setIsWideScreen] = useState(
    window.matchMedia("(min-aspect-ratio: 16/9)").matches
  );
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.matchMedia("(min-aspect-ratio: 16/9)").matches);
      setIsMobile(window.innerWidth <= 600);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const aboutProjectsStyle = {
    display: "flex",
    flexDirection: isWideScreen ? "row" : "column",
    justifyContent: isWideScreen ? "space-between" : "flex-start",
    alignItems: isWideScreen ? "flex-start" : "center",
    backgroundColor: "#353336",
    color: "#f2f2f2",
    padding: "20px",
    margin: "20px",
    borderRadius: "10px",
    width: "80%",
    maxWidth: "900px",
  };

  const projectAboutStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    textAlign: "left",
    padding: "20px",
    margin: isWideScreen ? "20px" : "0",
    borderRadius: "10px",
    width: isWideScreen ? "60%" : "100%",
  };

  const headerStyle = {
    color: "#ab9df2",
    marginBottom: "10px",
    marginTop: "20px",
    fontFamily: "'Roboto', sans-serif",
  };

  const headerStyleProjects = {
    color: "#ab9df2",
    marginTop: "20px",
    fontFamily: "'Roboto', sans-serif",
  };

  const headerStyleCard = {
    color: "#ab9df2",
    marginBottom: "10px",
    marginTop: "10px",
    fontFamily: "'Roboto', sans-serif",
  };

  const textStyle = {
    color: "#f8f8f2",
    marginBottom: "10px",
    fontFamily: "'Roboto', sans-serif",
  };

  const listStyle = {
    paddingLeft: "20px",
    lineHeight: "1.5",
    fontFamily: "'Roboto', sans-serif",
  };

  const imageSectionStyle = {
    height: "auto",
    marginTop: isWideScreen ? "0" : "20px",
    marginBottom: isWideScreen ? "0" : "20px",
    width: isWideScreen ? "40%" : "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    order: isWideScreen ? "2" : "0",
  };

  const imageStyle = {
    maxWidth: "100%",
    maxHeight: "360px",
    height: "auto",
    borderRadius: "2%",
  };

  const cardContainerStyle = {
    display: "flex",
    flexDirection: isWideScreen ? "row" : "column",
    justifyContent: "space-between",
    alignItems: isWideScreen ? "flex-start" : "center",
    gap: "20px",
    padding: "20px",
    marginTop: "20px",
    width: "80%",
    maxWidth: "900px",
  };

  const cardStyle = {
    backgroundColor: "#454245",
    color: "#f2f2f2",
    borderRadius: "10px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    width: isWideScreen ? "30%" : "100%",
  };

  const iconStyle = {
    width: "50px",
    height: "50px",
    marginBottom: "10px",
    backgroundColor: "white",
    borderRadius: "50%",
  };

  const contactSectionStyle = {
    backgroundColor: "#353336",
    color: "#f2f2f2",
    borderRadius: "10px",
    padding: "20px",
    marginTop: "40px",
    textAlign: "center",
    width: "80%",
    maxWidth: "900px",
    marginBottom: "20px",
    ...(isMobile && { fontSize: "14px", padding: "15px" }),
  };

  const contactItemStyle = {
    marginBottom: "10px",
    fontFamily: "'Roboto', sans-serif",
    fontSize: "16px",
    maxWidth: "100%",
    wordWrap: "break-word",
    overflowWrap: "break-word",
  };

  const linkStyle = {
    color: "#ab9df2",
    textDecoration: "none",
    wordWrap: "break-word",
    overflowWrap: "break-word",
  };

  return (
    <div className="App">
      <Navbar />

      <div style={aboutProjectsStyle}>
        <div style={imageSectionStyle}>
          <img src={CatImage} alt="Cat" style={imageStyle} />
        </div>
        <div style={projectAboutStyle}>
          <h2 style={headerStyle}>Who am I?</h2>
          <p style={textStyle}>
            Fullstack Developer specializing in crafting secure Frontend
            experiences with React and React Native, while building backends
            using JavaScript and Python.
          </p>

          <p style={textStyle}>Aspiring to be a true tech wizard.</p>
          <h2 style={headerStyle}>What I specialize at:</h2>
          <ul style={listStyle}>
            <li style={textStyle}>
              <strong>Frontend:</strong> React, React Native, JavaScript,
              TypeScript, Redux, HTML/CSS, NEXT.js, Expo
            </li>
            <li style={textStyle}>
              <strong>Backend:</strong> Docker, REST APIs, Node.js, PostgreSQL,
              Firestore (NoSQL), Express.js, Axios, Python, Redis, Gunicorn,
              Nginx, Electron
            </li>
            <li style={textStyle}>
              <strong>Cloud:</strong> Google, Cloudflare, Firebase, Bunny,
              Azure
            </li>
            <li style={textStyle}>
              <strong>Security Testing:</strong> Nmap, Burp Suite, Hydra, MobSF,
              Postman, Wireshark, OWASP, CWE
            </li>
            <li style={textStyle}>
              <strong>Other:</strong> Linux (Kali, Ubuntu, Debian), Git, GitHub,
              GitLab
            </li>
          </ul>
        </div>
      </div>

      <h2 style={headerStyleProjects}>My Most Recent Projects</h2>
      <div style={cardContainerStyle}>
        <div style={cardStyle}>
          <img src={AnimalFind} alt="Animalfind Icon" style={iconStyle} />
          <h3 style={headerStyleCard}>Animalfind</h3>
          <p style={textStyle}>
            A mobile app to help users find lost and found animals, based on
            geolocation feed.
          </p>
        </div>
        <div style={cardStyle}>
          <img src={avet} alt="Vulndbase Icon" style={iconStyle} />
          <h3 style={headerStyleCard}>Vulndbase</h3>
          <p style={textStyle}>
            An API to search through a database of vulnerabilities and exploits,
            with React visualizations.
          </p>
        </div>
        <div style={cardStyle}>
          <img src={avet} alt="Postwoman Icon" style={iconStyle} />
          <h3 style={headerStyleCard}>Postman Clone</h3>
          <p style={textStyle}>
            An offline desktop GUI API client for testing and interacting with
            RESTful APIs.
          </p>
        </div>
      </div>

      <div style={contactSectionStyle}>
        <h2 style={headerStyle}>Contact</h2>
        <p style={contactItemStyle}>
          Email:{" "}
          <a
            href="mailto:paul.shepherd.programming@gmail.com"
            style={linkStyle}
          >
            paul.shepherd.programming@gmail.com
          </a>
        </p>
        <p style={contactItemStyle}>Phone: +48 531-479-378</p>
        <p style={contactItemStyle}>
          LinkedIn:{" "}
          <a
            href="https://www.linkedin.com/in/paul-shepherd-programming/"
            style={linkStyle}
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.linkedin.com/in/paul-shepherd-programming/
          </a>
        </p>
        <p style={contactItemStyle}>
          Connect with me and let's create something magical!
        </p>
      </div>
    </div>
  );
};

export default App;
