import React from "react";
import Navbar from "./Components/Navbar";

const Skills = () => {
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "#2d2a2e",
    minHeight: "100vh",
  };

  const sectionStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    textAlign: "left",
    padding: "20px",
    margin: "20px",
    borderRadius: "10px",
    width: "80%",
    maxWidth: "900px",
    backgroundColor: "#353336",
    color: "#f8f8f2",
  };

  const headingStyle = {
    color: "#fc9867",
    fontFamily: "'Roboto', sans-serif",
    fontSize: "18px",
    marginBottom: "10px",
  };

  const subheadingStyle1 = {
    color: "#fc9867",
    fontFamily: "'Roboto', sans-serif",
    fontSize: "16px",
    marginBottom: "10px",
  };
  const subheadingStyle2 = {
    color: "#fc9867",
    fontFamily: "'Roboto', sans-serif",
    fontSize: "16px",
    marginBottom: "10px",
  };
  const subheadingStyle3 = {
    color: "#fc9867",
    fontFamily: "'Roboto', sans-serif",
    fontSize: "16px",
    marginBottom: "10px",
  };
  const subheadingStyle4 = {
    color: "#fc9867",
    fontFamily: "'Roboto', sans-serif",
    fontSize: "16px",
    marginBottom: "10px",
  };
  const subheadingStyle5 = {
    color: "#fc9867",
    fontFamily: "'Roboto', sans-serif",
    fontSize: "16px",
    marginBottom: "10px",
  };

  const listStyle = {
    listStyleType: "none",
    paddingLeft: "0",
    fontFamily: "'Roboto', sans-serif",
    color: "#f8f8f2",
    fontSize: "14px",
    lineHeight: "1.8",
  };

  return (
    <div className="App">
      <Navbar />
      <div style={containerStyle}>
        {/* Skills Section */}
        <div style={sectionStyle}>
          
          <h3 style={subheadingStyle1}>Frontend</h3>
          <ul style={listStyle}>
            <li>React, React Native, JavaScript, TypeScript, Redux, HTML/CSS, NEXT.js, Expo</li>
          </ul>
          <h3 style={subheadingStyle2}>Backend</h3>
          <ul style={listStyle}>
            <li>
              REST APIs, Node.js, PostgreSQL, Firestore (NoSQL database),
              Python, Docker, Redis, Gunicorn, Nginx, Electron, Express.js, Axios
            </li>
          </ul>
          <h3 style={subheadingStyle3}>Cloud</h3>
          <ul style={listStyle}>
            <li>Azure, Cloudflare, GitLab, Google, Firebase, Bunny</li>
          </ul>
          <h3 style={subheadingStyle4}>Security Testing</h3>
          <ul style={listStyle}>
            <li>
              Nmap, Burp Suite, OWASP, CWE, Hydra, MobSF, Postman, Wireshark
            </li>
          </ul>
          <h3 style={subheadingStyle5}>Other</h3>
          <ul style={listStyle}>
            <li>Linux (Kali, Ubuntu, Debian), Git, GitHub</li>
          </ul>
        </div>

        {/* Familiar With Section */}
        <div style={sectionStyle}>
          <h2 style={headingStyle}>Familiar With</h2>
          <ul style={listStyle}>
            <li>Web & Cloud Hosting</li>
            <li>RemixIDE, Solidity, AlgoKit</li>
            <li>VPS Administration</li>
            <li>IOT</li>
            <li>UX/UI Designing</li>
          </ul>
        </div>

        {/* Learning Path Section */}
        <div style={sectionStyle}>
          <h2 style={headingStyle}>Learning Path</h2>
          <ul style={listStyle}>
            <li>Building simple websites in CMS and plain HTML/CSS/JavaScript - 2020</li>
            <li>Customer Technical Support (dhosting.pl) - 2021</li>
            <li>Pentesting (Hack The Box exercises) - 2022</li>
            <li>Designing (logos, business cards, websites) - 2022</li>
            <li>
              Cisco CCNA Certification, ISO 27001 (WSB MERITO University) - 2022
            </li>
            <li>
              Building mobile and web apps with React/React Native - 2022
            </li>
            <li>
              Pentesting (Avet Information and Network Security) - 2024
            </li>
            <li>
              Building internal apps and SaaS products (Avet Information and
              Network Security) - 2024
            </li>
            <li>
              Building my own backend on VPS for personal project app
              AnimalFind (Personal Project) - 2024
            </li>
          </ul>
        </div>

        {/* Willing to Learn Section */}
        <div style={sectionStyle}>
          <h2 style={headingStyle}>Willing to Learn</h2>
          <ul style={listStyle}>
            <li>Any technology that might be important for a given position, project, or workflow</li>
          </ul>
        </div>

        {/* Languages Section */}
        <div style={sectionStyle}>
          <h2 style={headingStyle}>Languages</h2>
          <ul style={listStyle}>
            <li>Polish (Native)</li>
            <li>English (Upper-intermediate, B2/C1)</li>
            <li>Norwegian Bokmål dialect (Beginner, A1/A2)</li>
          </ul>
        </div>

        {/* Personal Interests Section */}
        <div style={sectionStyle}>
          <h2 style={headingStyle}>Personal Interests</h2>
          <ul style={listStyle}>
            <li>Cooking</li>
            <li>App Development</li>
            <li>Gardening</li>
            <li>Psychology</li>
            <li>Biology</li>
            <li>Philosophy</li>
            <li>Painting</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Skills;
