import React from "react";
import { Routes, Route } from "react-router-dom";
import App from "./App";
import Projects from "./Projects";
import Contact from "./Contact";
import Skills from "./Skills";
import Workflow from "./Workflow";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/skills" element={<Skills />} />
     
      <Route path="/workflow" element={<Workflow />} />
    </Routes>
  );
}

export default AppRoutes;
