import React from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import Navbar from "./Components/Navbar";

function Contact() {
  const navigate = useNavigate();

  return (
    <div className="App">
      <Navbar />

      <div className="aboutProjects">
        <h1>Contact</h1>

        <div className="Project">
          <div className="projectsIntent">
            <h3 className="tools" style={{ color: "white" }}>
              Email
            </h3>
            <p>paul.shepherd.programming@gmail.com</p>
            <h3 className="tools" style={{ color: "white" }}>
              Phone Number
            </h3>
            <p>+48 531-479-378</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
