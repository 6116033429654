import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../styles.css";

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();

  // Function to determine if the route matches
  const isActive = (route) => {
    return location.pathname === route;
  };

  return (
    <nav className="navbar">
      <ul className="nav-links">
        <li>
          <button
            className={`nav-item ${isActive("/") ? "active" : ""}`}
            onClick={() => navigate("/")}
          >
            About Me
          </button>
        </li>
        <li>
          <button
            className={`nav-item ${isActive("/projects") ? "active" : ""}`}
            onClick={() => navigate("/projects")}
          >
            Projects
          </button>
        </li>
        <li>
          <button
            className={`nav-item ${isActive("/skills") ? "active" : ""}`}
            onClick={() => navigate("/skills")}
          >
            Skills
          </button>
        </li>
        
        <li>
          <button
            className={`nav-item ${isActive("/workflow") ? "active" : ""}`}
            onClick={() => navigate("/workflow")}
          >
            How I Work
          </button>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
